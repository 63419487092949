import { DayDetails } from 'src/hooks/useWeekCalendar'
import { useWeekCalendar } from 'src/hooks/useWeekCalendar'
import { differenceInCalendarDays, getTime } from 'date-fns'
import React from 'react'
import { AiFillCaretLeft, AiFillCaretRight, AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'

type VenueDatePickerProps = {
    datePickerProps: any
}

export const VenueDatePicker: React.FC<VenueDatePickerProps> = ({ datePickerProps }) => {
    const { getWeekDetails, handleSetCursor, isDaySelected, onDateClick, todayTimestamp, selectedDate, monthString } =
        useWeekCalendar(getTime(datePickerProps.date))

    const daysStyle = (day: DayDetails) => {
        let baseStyle = `h-8 w-8 flex items-center justify-center font-medium rounded-full `
        baseStyle += day.month < 0 ? ' text-gray-400 ' : ' '
        baseStyle +=
            differenceInCalendarDays(day.timestamp, todayTimestamp) > 14
                ? ' text-gray-400 cursor-default '
                : ' cursor-pointer '
        baseStyle += isDaySelected(day) ? ' bg-red-500 text-white shadow-lg' : ' '
        baseStyle += day.timestamp < todayTimestamp ? ' text-gray-400 cursor-default' : ' cursor-pointer'
        return baseStyle
    }

    const handleDateClick = (timestamp: any) => {
        if (timestamp < todayTimestamp || differenceInCalendarDays(timestamp, todayTimestamp) > 14) return
        onDateClick(timestamp)
        datePickerProps.handleSetDate(new Date(timestamp))
    }

    const Calendar = () => {
        const weekDetails = getWeekDetails()
        const days = weekDetails.map((day, i) => {
            return (
                <div
                    onClick={() => handleDateClick(day.timestamp)}
                    className="flex items-center justify-between"
                    key={i}
                >
                    <div className={daysStyle(day)}>
                        <span>{day.day}</span>
                    </div>
                </div>
            )
        })
        return (
            <div className="flex-1 mt-4">
                <div className="flex justify-between mb-2 text-gray-600">
                    {['Hét', 'Ked', 'Sze', 'Csü', 'Pén', 'Szo', 'Vas'].map((d, i) => (
                        <div className="flex items-center justify-between" key={i}>
                            <div className="justify-center flex-items-center">{d}</div>
                        </div>
                    ))}
                </div>
                <div className="flex justify-between">{days}</div>
            </div>
        )
    }
    return (
        <div className="flex flex-col w-auto py-4 mt-4 bg-white rounded-lg text-fjord">
            <div className="flex items-center">
                <div className="flex items-center justify-between w-full">
                    <h3 className="text-2xl font-medium">{monthString}</h3>
                </div>
            </div>
            <div className="flex">
                <button onClick={() => handleSetCursor(-1)} className="mx-2 focus:outline-none">
                    <AiFillCaretLeft />
                </button>
                <Calendar />
                <button onClick={() => handleSetCursor(1)} className="mx-2 focus:outline-none">
                    <AiFillCaretRight />
                </button>
            </div>
        </div>
    )
}
