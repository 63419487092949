import { LoginForm } from '@components/section/Auth/LoginForm'
import { RegisterForm } from '@components/section/Auth/RegisterForm'
import SocialLogin from '@components/section/Auth/SocialLogin'
import React from 'react'
import { Modal } from './Modal'
import styled from '@emotion/styled'
import { useRouter } from 'next/router'
import { IoIosClose } from 'react-icons/io'
export const AuthModal: React.FC<{
    setIsAuthModal: (arg: boolean) => void
    authModalReg: boolean
    setIsAuthModalReg: (arg: boolean) => void
}> = ({ setIsAuthModal, authModalReg, setIsAuthModalReg }) => {
    const router = useRouter()
    const authCb = () => {
        setIsAuthModal(false)
    }
    return (
        <Modal setModal={setIsAuthModal}>
            <Form className="p-4 mt-40 bg-white rounded-lg xs:mt-30 xs:mb-30">
                <div className="flex justify-end">
                    <IoIosClose
                        className="w-6 h-6 text-gray-600 hover:text-blue-800"
                        onClick={() => setIsAuthModal(false)}
                    />
                </div>
                <h1 className="w-auto mb-8 text-3xl font-semibold text-center text-gray-900">
                    {authModalReg ? 'Regisztráció' : 'Bejelentkezés'}
                </h1>

                {authModalReg ? <RegisterForm /> : <LoginForm />}
                <div className="flex items-center justify-center mt-2 text-xs font-medium text-center">
                    <div className="w-8 border-t-2 border-gray-600" />
                    <p className="px-4">Vagy válaszd az alábbi lehetőségek egyikét</p>
                    <div className="w-8 border-t-2 border-gray-600" />
                </div>
                <SocialLogin onAuthCb={authCb} />
                <div className="flex items-center justify-center mt-12 mb-10 text-xs font-medium text-center">
                    <p className="px-4">{authModalReg ? 'Már regisztráltál?' : 'Még nincs fiókod?'}</p>
                    <button onClick={() => setIsAuthModalReg(!authModalReg)} className="underline uppercase">
                        {authModalReg ? 'BEJELENTKEZÉS' : 'REGISZTRÁCIÓ'}
                    </button>
                </div>
            </Form>
        </Modal>
    )
}

const Form = styled.div`
    max-width: 350px;
`
