import styled from '@emotion/styled'
import { Venue } from '@graphql/gqlRequests'
import bgImage from '@images/close-up_exp_minus1_15.jpg'
import Image from 'next/image'
import { TiStarFullOutline } from 'react-icons/ti'
import Slider from 'react-slick'

export const VenueDetailsHeader: React.FC<{ venue: Venue }> = ({ venue }) => {
    return (
        <div className="bg-white md:mt-32">
            <div className="mx-6 md:mx-auto md:container ">
                <div className="flex flex-col flex-1 mx-auto mt-8 md:mt-16">
                    <h1 className="text-lg font-semibold text-gray-900 md:text-3xl">{venue.name}</h1>
                    <div className="flex flex-col justify-start my-1 align-bottom sm:space-x-5 lg:justify-between lg:w-1/2 xl:w-1/3 sm:flex-row">
                        <div className="flex text-xs text-gray-600 ">
                            <div className="flex">
                                <TiStarFullOutline className="w-4 h-4 text-teal-500 fill-current" />
                                <TiStarFullOutline className="w-4 h-4 text-teal-500 fill-current" />
                                <TiStarFullOutline className="w-4 h-4 text-teal-500 fill-current" />
                                <TiStarFullOutline className="w-4 h-4 text-teal-500 fill-current" />
                                <TiStarFullOutline className="w-4 h-4 text-teal-500 fill-current" />
                                <div className="hidden ml-1">(119)</div>
                            </div>
                        </div>
                        {venue.courts?.length && (
                            <div className="text-xs text-gray-600 ">{venue.courts.length} pálya</div>
                        )}
                        <div className="text-xs text-gray-800 underline ">
                            {venue.address.zip}, {venue.address.city} {venue.address.line1}
                        </div>
                    </div>
                </div>
                <link
                    rel="stylesheet"
                    type="text/css"
                    charSet="UTF-8"
                    href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
                />
                <link
                    rel="stylesheet"
                    type="text/css"
                    href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
                />
                <div className="mt-8 md:hidden">
                    <SimpleSlider venueImages={venue.images} />
                </div>
                <VenueDetailsPicCointainer className="items-stretch hidden h-full md:flex">
                    <div className="w-full pr-1 md:w-1/2">
                        <img
                            className="object-cover w-full max-h-full rounded-l-lg"
                            src={venue.images[0]?.url}
                            alt=""
                        />
                    </div>
                    <div className="flex w-1/2 max-h-full pl-1">
                        <VenueImageContainer className="flex flex-col w-1/2 pr-1">
                            <img className="object-cover max-h-full pb-1" src={venue.images[1]?.url} alt="" />
                            <img className="object-cover max-h-full pt-1" src={venue.images[2]?.url} alt="" />
                        </VenueImageContainer>
                        <VenueImageContainer className="flex flex-col w-1/2 pl-1">
                            <img
                                className="object-cover max-h-full pb-1 rounded-tr-lg"
                                src={venue.images[3]?.url}
                                alt=""
                            />
                            <img
                                className="object-cover max-h-full pt-1 rounded-br-lg"
                                src={venue.images[4]?.url}
                                alt=""
                            />
                        </VenueImageContainer>
                    </div>
                </VenueDetailsPicCointainer>
            </div>
        </div>
    )
}

const VenueDetailsPicCointainer = styled.div`
    max-height: 400px;
`

const VenueImageContainer = styled.div`
    max-height: 50%;
`

export default VenueDetailsHeader

const SimpleSlider = ({ venueImages }) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    }

    return (
        <Slider {...settings}>
            {venueImages.map((val) => {
                return <img key={val.url} className="object-cover w-full h-48 rounded-lg" src={val.url} alt="" />
            })}
        </Slider>
    )
}
