import { useRef, useState } from 'react'
import {
    getWeek,
    startOfWeek,
    addDays,
    getDay,
    isSameMonth,
    getDate,
    addWeeks,
    getMonth,
    differenceInHours,
    differenceInWeeks,
    getTime,
    startOfDay,
} from 'date-fns'

export type WeekCalendar = {
    getWeekDetails: () => DayDetails[]
    handleSetCursor: (offset: number) => void
    isDaySelected: (day: DayDetails) => boolean
    onDateClick: (timestamp: number) => void
    todayTimestamp: number
    selectedDate: number | Date
    monthString: string
}

export type DayDetails = {
    day: number
    dayString: string
    monthOffset: boolean
    month: number
    timestamp: number
}
const daysMap = ['Hétfő', 'Kedd', 'Szerda', 'Csütörtök', 'Péntek', 'Szombat', 'Vasárnap']

const monthMap = [
    'Január',
    'Február',
    'Március',
    'Április',
    'Május',
    'Június',
    'Július',
    'Augusztus',
    'Szeptember',
    'Október',
    'November',
    'December',
]

export const useWeekCalendar = (initialDate: Date | number | null): WeekCalendar => {
    const todayTimestamp = startOfDay(Date.now()).getTime()
    const initialNormalizedDate = initialDate ? startOfDay(initialDate).getTime() : null
    const [selectedDate, setSelectedDate] = useState<Date | number | null>(initialNormalizedDate)

    const initialCursorDate = selectedDate || new Date()
    const initialCursor = startOfWeek(initialCursorDate, { weekStartsOn: 1 })
    const [cursor, setCursor] = useState<Date | number>(initialCursor)

    const [monthValue, setMonthValue] = useState(getMonth(selectedDate))

    const getWeekDetails = (): DayDetails[] => {
        const weekArray = Array(7).fill(null)
        return weekArray.map((_, i) => {
            const day = addDays(cursor, i)
            const dayIndexStd = getDay(day)
            const dayIndex = dayIndexStd === 0 ? 6 : dayIndexStd - 1
            const monthOffset = isSameMonth(cursor, day)
            return {
                day: getDate(day),
                dayString: daysMap[dayIndex],
                monthOffset,
                month: getMonth(day),
                timestamp: getTime(day),
            }
        })
    }

    const handleSetCursor = (offset: number): void => {
        //@ 2 weeks ahead lock
        const projectedWeek = differenceInWeeks(todayTimestamp, addWeeks(cursor, offset))
        if (projectedWeek < -1 || projectedWeek > 0) return
        const cursor2 = addWeeks(cursor, offset)
        setCursor(cursor2)
        setMonthValue(getMonth(cursor2))
    }

    const onDateClick = (timestamp: number): void => {
        if (timestamp >= todayTimestamp) {
            setSelectedDate(timestamp)
        }
    }

    const isDaySelected = ({ timestamp }: DayDetails) => {
        return timestamp == selectedDate
    }

    return {
        getWeekDetails,
        handleSetCursor,
        isDaySelected,
        onDateClick,
        todayTimestamp,
        selectedDate,
        monthString: monthMap[monthValue],
    }
}
