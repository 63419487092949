import { isServer } from '@util/config'
import { useEffect } from 'react'
import { createPortal } from 'react-dom'

export const Portal: React.FC<{ isOpen: boolean }> = ({ children, isOpen }) => {
    return !isServer && <PortalContent isOpen={isOpen}>{children}</PortalContent>
}

export const PortalContent: React.FC<{ isOpen: boolean }> = ({ children, isOpen }) => {
    const mount = document.getElementById('portal-root')
    const el = document.createElement('div')

    useEffect((): (() => void) => {
        if (isOpen) {
            document.body.classList.add('overflow-hidden')
        } else {
            document.body.classList.remove('overflow-hidden')
        }
        mount?.appendChild(el)
        return () => mount?.removeChild(el)
    }, [el, mount, isOpen])

    return isOpen && createPortal(children, el)
}
