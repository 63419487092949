import { format } from 'date-fns'
const VenueTimesPicker: React.FC<{ bookableSlots: any }> = ({ bookableSlots }) => {
    return (
        <div className="flex flex-col items-center px-4 overflow-y-auto max-h-40">
            {bookableSlots.slots.map((val, i) => {
                return (
                    <div
                        onClick={() => bookableSlots.setFromTime(val.from)}
                        className={`flex rounded-lg justify-between w-full px-4 my-1 items-center cursor-pointer hover:text-white hover:bg-red-500 ${
                            val.from === bookableSlots.fromTime ? 'bg-red-500 text-white' : null
                        }`}
                        key={i}
                    >
                        <div className="flex">
                            <div className="flex items-center py-2 mr-1">{format(new Date(val.from), 'HH:mm')}</div>
                            <p className="flex items-center justify-center">-</p>
                            <div className="flex items-center py-2 ml-1">{format(new Date(val.to), 'HH:mm')}</div>
                        </div>
                        <div>{Math.floor(val.price)} Ft</div>
                    </div>
                )
            })}
            {bookableSlots.slots.length ? null : <div>A mai napra nincs szabad időpont.</div>}
        </div>
    )
}
export default VenueTimesPicker
