import { AuthModal } from '@components/global/AuthModal'
import { Loader } from '@components/global/Loader'
import { Venue } from '@graphql/gqlRequests'
import { isServer } from '@util/config'
import set from 'date-fns/esm/set/index'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { TiStarFullOutline } from 'react-icons/ti'
import { useSearchContext } from 'src/contexts/SearchContext'
import { setTimeout } from 'timers'
import {
    useCanCreateBookingLazyQuery,
    useCanCreateBookingQuery,
    useGetBookableSlotsQuery,
} from '../../../graphql/gqlHooks'
import { ErrorToastMessage } from '../../global/ErrorToastMessage'
import { Portal } from '../../global/Portal/Portal'
import { VenueDatePicker } from '../Venue/VenueDatePicker'
import VenueTimesPicker from '../Venue/VenueTimesPicker'
type VenueBookingPicker = {
    venue: Venue
}

export const VenueBookingPicker: React.FC<VenueBookingPicker> = ({ venue }) => {
    const router = useRouter()
    const { searchParams, setSearchParams, setCheckout } = useSearchContext()
    const [date, setDate] = useState(searchParams?.date ? new Date(searchParams?.date) : new Date())
    const [fromTime, setFromTime] = useState<any>(null)
    const [booked, setIsBooked] = useState(false)
    const [bookingError, setBookingError] = useState(false)
    const [authModal, setIsAuthModal] = useState(false)
    const [authModalReg, setIsAuthModalReg] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState(false)

    const {
        data: canCreateData,
        refetch: canCreateRefetch,
        error: canCreateError,
    } = useCanCreateBookingQuery({
        skip: !isSubmitted,
    })

    const { data, loading } = useGetBookableSlotsQuery({
        variables: {
            date,
            length: searchParams.duration * 60,
            venueId: venue.id,
        },
        pollInterval: 60 * 1000,
        skip: isServer,
    })

    const durations = [1, 1.5, 2, 3]
    const handleDuration = (val: number) => {
        setSearchParams({
            ...searchParams,
            duration: val,
        })
    }

    useEffect(() => {
        if (booked) {
            setTimeout(() => {
                setIsBooked(false)
            }, 2000)
        }
    }, [booked])

    useEffect(() => {
        if (bookingError) {
            setTimeout(() => {
                setBookingError(false)
            }, 2000)
        }
    }, [bookingError])

    const handleSetDate = (val) => {
        setDate(val)
        setFromTime(null)
    }

    const handleBooking = async () => {
        const bookingDetails = data.getBookableSlots.find((val) => {
            setIsBooked(true)
            return val.from === fromTime
        })
        if (!bookingDetails) return
        const { from, price, to, venueId } = bookingDetails
        try {
            setIsSubmitted(true)
            const result = await canCreateRefetch({ from: from, price: price, to: to, venueId: venueId })

            if (result.data.canCreateBooking) {
                setCheckout({
                    from,
                    to,
                    venueId,
                    price,
                })
                router.push('/foglalas')
            }
        } catch (_) {
            setBookingError(true)
        }
    }
    return (
        <>
            {canCreateError && bookingError ? (
                <ErrorToastMessage setIsShown={setBookingError} message={canCreateError.message} />
            ) : null}
            <div className="sticky top-0">
                <div className="p-6 mt-12 border border-gray-300 rounded-lg shadow-md hover:shadow-lg venue-booking-picker">
                    <div className="flex flex-col">
                        <div className="flex text-2xl font-semibold space-between">
                            <div>
                                <h1>Add meg az </h1>
                                <h1>időpontokat az árak megtekintéséhez</h1>
                            </div>
                            <div className="flex hidden ml-4 text-sm">
                                <TiStarFullOutline className="w-4 h-4 text-teal-500 fill-current" />
                                <p>4,9</p>
                                <p className="ml-1 text-gray-600">(12)</p>
                            </div>
                        </div>
                        <div>
                            <div className="flex flex-col justify-center mt-12">
                                <h4 className="text-sm font-semibold">Játék időtartama</h4>
                                <div className="flex flex-wrap mt-2 bg-white rounded-lg">
                                    {durations.map((val) => {
                                        return (
                                            <div className="flex items-center justify-center w-1/2 py-2 " key={val}>
                                                <button
                                                    onClick={(e) => handleDuration(val)}
                                                    className={`w-32 flex items-center justify-center p-4 px-3 py-2 text-sm font-normal  border  rounded-lg outline-none whitespace-nowrap focus:outline-none focus: ${
                                                        searchParams.duration === val
                                                            ? 'bg-red-500 font-semibold text-white'
                                                            : 'text-gray-900'
                                                    }`}
                                                >
                                                    {val} óra
                                                </button>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="flex flex-col w-full">
                                <VenueDatePicker datePickerProps={{ date, handleSetDate }} />
                                {data && !loading && (
                                    <VenueTimesPicker
                                        bookableSlots={{ slots: data.getBookableSlots, fromTime, setFromTime }}
                                    />
                                )}
                                {loading && (
                                    <div className="flex flex-col items-center h-40 px-4 ">
                                        <Loader />
                                    </div>
                                )}
                            </div>

                            <div className="flex flex-col justify-center mx-4 mt-6">
                                <button
                                    onClick={handleBooking}
                                    className={`${
                                        fromTime
                                            ? 'bg-teal-600 hover:bg-teal-800 hover:shadow-lg'
                                            : 'bg-gray-400 cursor-not-allowed '
                                    } p-4 text-white  rounded-lg outline-none focus:outline-none`}
                                    disabled={fromTime ? false : true}
                                >
                                    Foglalás
                                </button>

                                {authModal && (
                                    <Portal isOpen={authModal}>
                                        <AuthModal
                                            setIsAuthModal={setIsAuthModal}
                                            setIsAuthModalReg={setIsAuthModalReg}
                                            authModalReg={authModalReg}
                                        />
                                    </Portal>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <style jsx>
                {`
                    .venue-booking-picker {
                        max-width: 380px;
                    }
                `}
            </style>
        </>
    )
}
