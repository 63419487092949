import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import styled from '@emotion/styled'
import { MdEmail, MdVpnKey } from 'react-icons/md'
import { useLoginMutation } from '@graphql/gqlHooks'
import { useRouter } from 'next/router'
import { useAuth } from 'src/contexts/AuthContext'
import Link from 'next/link'
import { useSearchContext } from '../../../contexts/SearchContext'
import useAmplitude from '../../../modules/amplitude/useAmplitude'

const InputContainer = styled.div`
    max-width: 350px;
`

export const LoginForm: React.FC = () => {
    const { setJwt } = useAuth()
    const router = useRouter()
    const { checkout } = useSearchContext()
    const { amplitudeInstance } = useAmplitude()
    const [sendLogin, { loading, error }] = useLoginMutation({
        onCompleted(data) {
            if (data.login) {
                amplitudeInstance.logEvent('login')
                setJwt(data.login)
                if (checkout.venueId) {
                    router.push('/foglalas')
                } else {
                    router.back()
                }
            }
        },
    })

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().email('Nem megfelelő email formátum').required('Adj meg email címet'),
            password: Yup.string()
                .min(8, 'Adj meg legalább 8 karakterből álló jelszót')
                .required('Mindenképpen meg kell adnod jelszót'),
        }),
        onSubmit: async (values) => {
            await sendLogin({
                variables: {
                    input: values,
                },
            })
        },
    })

    return (
        <form className="flex flex-col items-center w-full space-y-4" onSubmit={formik.handleSubmit}>
            <InputContainer className="flex flex-col w-full">
                <div className="relative flex items-center">
                    <input
                        id="email"
                        type="email"
                        autoCapitalize="email"
                        className={`w-full py-3 pl-10 pr-4 text-lg bg-transparent border-2 border-gray-600 rounded-lg outline-none
                            ${formik.touched.email && formik.errors.email ? 'border-red-600' : ''}`}
                        placeholder="Email"
                        {...formik.getFieldProps('email')}
                    />
                    <MdEmail className="absolute ml-4 text-gray-600" />
                </div>
                <div className="h-2 mt-1 text-sm text-red-600">
                    {formik.touched.email && formik.errors.email ? formik.errors.email : ''}
                </div>
            </InputContainer>
            <InputContainer className="flex flex-col w-full">
                <div className="relative flex items-center">
                    <input
                        id="password"
                        type="password"
                        autoComplete="passoword"
                        className={`w-full py-3 pl-10 pr-4 text-lg bg-transparent border-2 border-gray-600 rounded-lg outline-none
                            ${formik.touched.password && formik.errors.password ? 'border-red-600' : ''}`}
                        placeholder="Jelszó"
                        {...formik.getFieldProps('password')}
                    />
                    <MdVpnKey className="absolute ml-4 text-gray-600" />
                </div>
                <div className="h-2 mt-1 text-sm text-red-600">
                    {formik.touched.password && formik.errors.password ? formik.errors.password : ''}
                </div>
            </InputContainer>

            <button
                type="submit"
                className={`w-full px-6 py-3 text-base border-2 font-medium bg-teal-600 outline-none hover:bg-teal-800 hover:shadow-lg rounded-md shadow-sm text-white
                ${loading ? 'loader' : null}`}
            >
                Tovább
            </button>
            <div className="mb-0 text-sm text-red-600">{error ? ` ${error}` : null}</div>
        </form>
    )
}
