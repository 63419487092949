import React, { useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'

type MapModalProps = {
    setModal: React.Dispatch<React.SetStateAction<boolean>>
}

export const Modal: React.FC<MapModalProps> = ({ setModal, children }) => {
    const node = useRef<HTMLDivElement>(null)

    const escapeHandler = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            setModal(false)
        }
    }

    const handleClick = (e) => {
        if (node?.current?.contains(e.target)) return
        setModal(false)
    }

    useEffect(() => {
        document.addEventListener('keydown', escapeHandler)
        document.addEventListener('mousedown', handleClick)
        document.body.classList.add('overflow-hidden')
        return () => {
            document.removeEventListener('keydown', escapeHandler)
            document.removeEventListener('mousedown', handleClick)
            document.body.classList.remove('overflow-hidden')
        }
    })

    return (
        <ModalContainer className="fixed inset-0 z-50 flex items-center justify-center w-screen h-screen pt-40 pb-40 overflow-y-scroll">
            <div ref={node}>{children}</div>
        </ModalContainer>
    )
}

const ModalContainer = styled.div`
    background-color: rgba(0, 0, 0, 0.4);
`
