import { FaBuffer, FaFacebook, FaFacebookF, FaGoogle, FaInstagram } from 'react-icons/fa'
import Link from 'next/link'
import { HiOutlineMail } from 'react-icons/hi'
export const Footer: React.FC = () => {
    return (
        <>
            <footer className="mb-16 bg-gray-800 md:mb-0" aria-labelledby="footerHeading">
                <h2 id="footerHeading" className="sr-only">
                    Footer
                </h2>
                <div className="px-4 py-12 mx-auto max-w-7xl sm:px-6 lg:py-16 lg:px-8">
                    <div className="pb-8 xl:grid xl:grid-cols-5 xl:gap-8">
                        <div className="grid grid-cols-2 gap-8 xl:col-span-4">
                            <div className="md:grid md:grid-cols-2 md:gap-8">
                                <div>
                                    <h3 className="text-sm font-semibold tracking-wider text-gray-400 uppercase">
                                        Partner
                                    </h3>
                                    <ul className="mt-4 space-y-4">
                                        <li>
                                            <Link href="/partner">
                                                <a className="text-base text-gray-300 cursor-pointer hover:text-white">
                                                    Tájékoztató
                                                </a>
                                            </Link>
                                        </li>

                                        <li>
                                            <Link href="/contact">
                                                <a className="text-base text-gray-300 cursor-pointer hover:text-white">
                                                    Kapcsolat
                                                </a>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="mt-12 md:mt-0">
                                    <h3 className="text-sm font-semibold tracking-wider text-gray-400 uppercase">
                                        Ügyfélszolgálat
                                    </h3>
                                    <ul className="mt-4 space-y-4">
                                        <Link href="/contact">
                                            <a className="text-base text-gray-300 cursor-pointer hover:text-white">
                                                Kapcsolat
                                            </a>
                                        </Link>
                                    </ul>
                                </div>
                            </div>
                            <div className="md:grid md:grid-cols-2 md:gap-8">
                                <div>
                                    <h3 className="text-sm font-semibold tracking-wider text-gray-400 uppercase">
                                        Legal
                                    </h3>
                                    <ul className="mt-4 space-y-4">
                                        <li>
                                            <Link href="/aszf">
                                                <a
                                                    target="_blank"
                                                    className="text-base text-gray-300 cursor-pointer hover:text-white"
                                                >
                                                    Általános szerződési feltételek
                                                </a>
                                            </Link>
                                        </li>

                                        <li>
                                            <Link href="/adatkezelesi_tajekoztato">
                                                <a
                                                    target="_blank"
                                                    className="text-base text-gray-300 cursor-pointer hover:text-white"
                                                >
                                                    Adatkezelési tájékoztató
                                                </a>
                                            </Link>
                                        </li>

                                        <li>
                                            <Link href="/cookie_szabalyzat">
                                                <a
                                                    target="_blank"
                                                    className="text-base text-gray-300 cursor-pointer hover:text-white"
                                                >
                                                    Cookie szabályzat
                                                </a>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="flex mt-12 xl:mt-0 place-items-center">
                            <div className="flex items-center justify-between py-4 pl-4 md:pl-12">
                                <Link href="/">
                                    <a className="flex items-center text-red-500 cursor-pointer ">
                                        <FaBuffer className="w-10 h-10 p-2 rounded-full" />
                                        <span className="block ml-1 text-2xl font-semibold ">Foglaljmost</span>
                                    </a>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="pt-8 border-t border-gray-700 lg:flex lg:items-center lg:justify-between xl:mt-0">
                        <div>
                            <h3 className="text-sm font-semibold tracking-wider text-gray-400 uppercase">
                                Iratkozz fel a hírlevelünkre!
                            </h3>
                            <p className="mt-2 text-base text-gray-300">
                                Pályák, amatőr versenyek, hírek. Ne maradj le semmiről.
                            </p>
                        </div>
                        <form className="mt-4 sm:flex sm:max-w-md lg:mt-0">
                            <input
                                type="email"
                                name="emailAddress"
                                id="emailAddress"
                                className="w-full min-w-0 px-4 py-2 text-base text-gray-900 placeholder-gray-500 bg-white border border-transparent rounded-md appearance-none focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white focus:border-white focus:placeholder-gray-400 sm:max-w-xs"
                                placeholder="Email cím"
                            />
                            <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                                <button
                                    type="submit"
                                    className="flex items-center justify-center w-full px-4 py-2 text-base font-medium text-white bg-indigo-500 border border-transparent rounded-md hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
                                >
                                    Feliratkozás
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className="pt-8 mt-8 border-t border-gray-700 md:flex md:items-center md:justify-between">
                        <div className="flex space-x-6 md:order-2">
                            <Link href="https://www.facebook.com/Foglaljmost-103747514880453">
                                <a target="_blank" className="text-gray-400 cursor-pointer hover:text-gray-300">
                                    <span className="sr-only">Facebook</span>
                                    <FaFacebook className="w-6 h-6" />
                                </a>
                            </Link>

                            <a href="mailto:hello@foglaljmost.hu" className="text-gray-400 hover:text-gray-300">
                                <span className="sr-only">Instagram</span>
                                <HiOutlineMail className="w-6 h-6" />
                            </a>
                        </div>
                        <p className="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
                            © 2021 Foglaljmost.hu, Minden jog fenntartva.
                        </p>
                    </div>
                </div>
            </footer>
        </>
    )
}
