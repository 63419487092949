import { Venue } from '@graphql/gqlRequests'
import { GoogleMap, Marker } from '@react-google-maps/api'
import React, { useEffect, useState } from 'react'
import { GiFlexibleLamp } from 'react-icons/gi'
import { useBreakpoint } from 'src/hooks/useBreakpoint'

type VenueDetailsMapProps = {
    venue: Venue
}

export const VenueDetailsMap: React.FC<VenueDetailsMapProps> = ({ venue }) => {
    const [containerStyle, setContainerStyle] = useState({ width: '400px', height: '400px' })
    const breakpoint = useBreakpoint()
    useEffect(() => {
        if (breakpoint === 'sm') {
            setContainerStyle({ width: '250px', height: '300px' })
        }
        if (breakpoint === 'md') {
            setContainerStyle({ width: '350px', height: '350px' })
        }
        if (breakpoint === 'lg') {
            setContainerStyle({ width: '480px', height: '350px' })
        }
        if (breakpoint === 'xl') {
            setContainerStyle({ width: '600px', height: '400px' })
        }
        if (breakpoint === '2xl') {
            setContainerStyle({ width: '800px', height: '500px' })
        }
    }, [breakpoint])

    const center = {
        lat: venue.address.lat,
        lng: venue.address.lng,
    }

    return (
        <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={15} options={{ gestureHandling: 'greedy' }}>
            <Marker key={venue.id} position={{ lat: venue.address.lat, lng: venue.address.lng }} />
        </GoogleMap>
    )
}
