import { isSSR } from '@util/isSSR'
import { debounce } from 'lodash'
import { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'

export const useBreakpoint = (): string | undefined => {
    const { width } = useWindowSize()
    const getBreakpoint = useCallback(
        () => (isSSR ? undefined : window.getComputedStyle(document.body, '::after').content.replace(/"/g, '')),
        [],
    )
    const [breakpoint, setBreakpoint] = useState(getBreakpoint)

    useEffect(() => {
        setBreakpoint(getBreakpoint)
    }, [width, getBreakpoint])

    return breakpoint
}

const useWindowSize = (
    wait = 250,
): {
    width?: number
    height?: number
} => {
    const getWindowSize = useCallback(
        () => ({
            width: isSSR ? undefined : window.innerWidth,
            height: isSSR ? undefined : window.innerHeight,
        }),
        [],
    )
    const [windowSize, setWindowSize] = useState(getWindowSize)

    useResize(() => {
        setWindowSize(getWindowSize)
    }, wait)

    return windowSize
}

const useResize = (callback: (event: Event) => void, wait = 250): void => {
    const handleResize = useMemo(
        () => (wait !== 0 ? debounce((event: Event) => callback(event), wait) : (event: Event) => callback(event)),
        [wait, callback],
    )

    useEventListener({
        type: 'resize',
        listener: handleResize,
        options: { passive: true },
    })
}

interface UseEventListener {
    type: keyof WindowEventMap
    listener: EventListener
    element?: RefObject<Element> | Document | Window | null
    options?: AddEventListenerOptions
}

export const useEventListener = ({
    type,
    listener,
    element = isSSR ? undefined : window,
    options,
}: UseEventListener): void => {
    const savedListener = useRef<EventListener>()

    useEffect(() => {
        savedListener.current = listener
    }, [listener])

    const handleEventListener = useCallback((event: Event) => {
        savedListener.current?.(event)
    }, [])

    useEffect(() => {
        const target = getRefElement(element)
        target?.addEventListener(type, handleEventListener, options)
        return () => target?.removeEventListener(type, handleEventListener)
    }, [type, element, options, handleEventListener])
}

export const getRefElement = <T>(element?: RefObject<Element> | T): Element | T | undefined | null => {
    if (element && 'current' in element) {
        return element.current
    }

    return element as any
}
