// tslint:disable max-line-length
// tslint:disable no-namespace
// tslint:disable max-classes-per-file
// tslint:disable trailing-comma
// tslint:disable no-string-literal
// tslint:disable no-shadowed-variable array-type
/* eslint-disable */

export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string
    String: string
    Boolean: boolean
    Int: number
    Float: number
    /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
    DateTime: any
}

export type AddressInput = {
    country: Scalars['String']
    state: Scalars['String']
    zip: Scalars['String']
    city: Scalars['String']
    line1: Scalars['String']
}

export type BookableOutput = {
    __typename?: 'BookableOutput'
    id: Scalars['ID']
    venueId: Scalars['String']
    from: Scalars['DateTime']
    to: Scalars['DateTime']
    price: Scalars['Float']
}

export type BookingEditable = {
    __typename?: 'BookingEditable'
    isEditable: Scalars['Boolean']
    price: Scalars['Float']
}

export enum BookingStatus {
    InProgress = 'IN_PROGRESS',
    Cancelled = 'CANCELLED',
    Completed = 'COMPLETED',
}

export type BusinessHours = {
    __typename?: 'BusinessHours'
    id: Scalars['ID']
    dayOfWeek: Scalars['Float']
    openTime: Scalars['String']
    closeTime: Scalars['String']
    courtId: Scalars['String']
    court: Court
    venueId: Scalars['String']
    venue: Venue
}

export type BusinessHoursInput = {
    openTime: Scalars['String']
    closeTime: Scalars['String']
    dayOfWeek: Scalars['Float']
}

export type Court = {
    __typename?: 'Court'
    id: Scalars['ID']
    name: Scalars['String']
    surface: CourtSurface
    type: CourtType
    lights: Scalars['Boolean']
    usableDuringWinter: Scalars['Boolean']
    price: Scalars['Float']
    venueId: Scalars['String']
    venue: Venue
    bookings: Array<Booking>
    businessHours: Array<BusinessHours>
}

export enum CourtSurface {
    Clay = 'CLAY',
    Hard = 'HARD',
    Carpet = 'CARPET',
    Grass = 'GRASS',
}

export enum CourtType {
    Outdoor = 'OUTDOOR',
    Indoor = 'INDOOR',
}

export type EditProfileInput = {
    firstName?: Maybe<Scalars['String']>
    lastName?: Maybe<Scalars['String']>
    gender?: Maybe<Scalars['String']>
    country?: Maybe<Scalars['String']>
    zip?: Maybe<Scalars['String']>
    city?: Maybe<Scalars['String']>
    line1?: Maybe<Scalars['String']>
}

export type Event = {
    __typename?: 'Event'
    id: Scalars['ID']
    title: Scalars['String']
    description: Scalars['String']
    venueId: Scalars['String']
    createdAt: Scalars['DateTime']
    venue: Venue
}

export enum GenderEnum {
    NotProvided = 'NOT_PROVIDED',
    Woman = 'WOMAN',
    Man = 'MAN',
}

export type Image = {
    __typename?: 'Image'
    id: Scalars['ID']
    url: Scalars['String']
    venueId: Scalars['String']
    venue: Venue
}

export type Location = {
    __typename?: 'Location'
    location: Scalars['String']
    count: Scalars['Int']
}

export type LoginInput = {
    email: Scalars['String']
    password: Scalars['String']
}

export type Mutation = {
    __typename?: 'Mutation'
    createVenue: Venue
    register: Scalars['String']
    socialAuth: Scalars['String']
    login: Scalars['String']
    changePassword: Scalars['Boolean']
    editProfile: User
    getSmsValidationByPhone: Scalars['Boolean']
    validate: Scalars['Boolean']
    createBooking: Booking
    editBooking: Booking
    removeBooking: Scalars['Boolean']
    removeBookingByAdmin: Scalars['Boolean']
    storePartnerContact: Scalars['Boolean']
}

export type MutationCreateVenueArgs = {
    input: VenueInput
}

export type MutationRegisterArgs = {
    input: RegisterInput
}

export type MutationSocialAuthArgs = {
    input: SocialAuthInput
}

export type MutationLoginArgs = {
    input: LoginInput
}

export type MutationChangePasswordArgs = {
    newPasswordAgain: Scalars['String']
    newPassword: Scalars['String']
    currentPassword: Scalars['String']
}

export type MutationEditProfileArgs = {
    input: EditProfileInput
}

export type MutationGetSmsValidationByPhoneArgs = {
    lastName: Scalars['String']
    firstName: Scalars['String']
    phone: Scalars['String']
}

export type MutationValidateArgs = {
    code: Scalars['String']
}

export type MutationCreateBookingArgs = {
    repeat?: Maybe<Scalars['Float']>
    courtId?: Maybe<Scalars['String']>
    comment: Scalars['String']
    price: Scalars['Float']
    to: Scalars['DateTime']
    from: Scalars['DateTime']
    venueId: Scalars['String']
}

export type MutationEditBookingArgs = {
    comment?: Maybe<Scalars['String']>
    courtId: Scalars['String']
    to: Scalars['DateTime']
    from: Scalars['DateTime']
    bookingId: Scalars['String']
}

export type MutationRemoveBookingArgs = {
    bookingId: Scalars['String']
}

export type MutationRemoveBookingByAdminArgs = {
    bookingId: Scalars['String']
}

export type MutationStorePartnerContactArgs = {
    analiticsId?: Maybe<Scalars['String']>
    mail: Scalars['String']
}

export type Price = {
    __typename?: 'Price'
    id: Scalars['ID']
    dayOfWeek: Scalars['Float']
    from: Scalars['String']
    to: Scalars['String']
    price: Scalars['Float']
    venueId: Scalars['String']
    venue: Venue
}

export type Query = {
    __typename?: 'Query'
    getCourts: Array<Court>
    getCourtsOfVenue: Array<Court>
    getCourtsOfMyVenue: Array<Court>
    getBusinessHours: Array<BusinessHours>
    getBusinessHoursOfVenue: Array<BusinessHours>
    getBusinessHoursOfMyVenue: Array<BusinessHours>
    getBusinessHoursOfVenueAtDay: Array<BusinessHours>
    getVenue: Venue
    getVenues: Array<Venue>
    getMyVenue: Venue
    searchVenues: Array<Venue>
    searchLocationsAndVenues: Array<SearchResult>
    getVenueTypes: Array<VenueType>
    me: User
    getRolesOfUser: Array<Role>
    getPrices: Array<Price>
    getPricesOfVenue: Array<Price>
    getPricesOfVenueAtDay: Array<Price>
    getPriceOfBooking: Scalars['Int']
    canCreateBooking: Scalars['Boolean']
    canEditBooking: BookingEditable
    getBookings: Array<Booking>
    getBookingsOfMyVenue: Array<Booking>
    getBookingsOfUser: Array<Booking>
    getBookableSlots: Array<BookableOutput>
    getMemberRole: Role
    getMyEvents: Array<Event>
    getImages: Array<Image>
    getImagesOfVenue: Array<Image>
}

export type QueryGetCourtsOfVenueArgs = {
    venueId: Scalars['String']
}

export type QueryGetBusinessHoursOfVenueArgs = {
    venueId: Scalars['String']
}

export type QueryGetBusinessHoursOfVenueAtDayArgs = {
    dayOfWeek: Scalars['Float']
    venueId: Scalars['String']
}

export type QueryGetVenueArgs = {
    idOrSlug: Scalars['String']
}

export type QuerySearchVenuesArgs = {
    keyword?: Maybe<Scalars['String']>
}

export type QuerySearchLocationsAndVenuesArgs = {
    keyword?: Maybe<Scalars['String']>
}

export type QueryGetRolesOfUserArgs = {
    userId: Scalars['String']
}

export type QueryGetPricesOfVenueArgs = {
    venueId: Scalars['String']
}

export type QueryGetPricesOfVenueAtDayArgs = {
    dayOfWeek: Scalars['Float']
    venueId: Scalars['String']
}

export type QueryGetPriceOfBookingArgs = {
    to: Scalars['DateTime']
    from: Scalars['DateTime']
    venueId: Scalars['String']
}

export type QueryCanCreateBookingArgs = {
    repeat?: Maybe<Scalars['Float']>
    courtId?: Maybe<Scalars['String']>
    comment: Scalars['String']
    price: Scalars['Float']
    to: Scalars['DateTime']
    from: Scalars['DateTime']
    venueId: Scalars['String']
}

export type QueryCanEditBookingArgs = {
    courtId: Scalars['String']
    to: Scalars['DateTime']
    from: Scalars['DateTime']
    bookingId: Scalars['String']
}

export type QueryGetBookingsOfMyVenueArgs = {
    to?: Maybe<Scalars['DateTime']>
    from?: Maybe<Scalars['DateTime']>
}

export type QueryGetBookableSlotsArgs = {
    length: Scalars['Float']
    date: Scalars['DateTime']
    venueId: Scalars['String']
}

export type QueryGetImagesOfVenueArgs = {
    venueId: Scalars['String']
}

export type RegisterInput = {
    email: Scalars['String']
    password: Scalars['String']
}

export type Role = {
    __typename?: 'Role'
    id: Scalars['ID']
    name: Scalars['String']
    rank: Scalars['Float']
    users: Array<User>
}

export type SearchResult = Location | Venue

export type SeasonBooking = {
    __typename?: 'SeasonBooking'
    id: Scalars['ID']
    bookings: Array<Booking>
}

export type SocialAuthInput = {
    provider: Scalars['String']
    accessToken?: Maybe<Scalars['String']>
    code?: Maybe<Scalars['String']>
}

export type User = {
    __typename?: 'User'
    id: Scalars['ID']
    firstName?: Maybe<Scalars['String']>
    lastName?: Maybe<Scalars['String']>
    gender: GenderEnum
    email: Scalars['String']
    address?: Maybe<Address>
    source: Scalars['String']
    validated: Scalars['Boolean']
    phone?: Maybe<Scalars['String']>
    avatar?: Maybe<Scalars['String']>
    createdAt: Scalars['DateTime']
    updatedAt: Scalars['DateTime']
    bookings?: Maybe<Array<Booking>>
    venue?: Maybe<Venue>
    roles: Array<Role>
}

export type Venue = {
    __typename?: 'Venue'
    id: Scalars['ID']
    name: Scalars['String']
    venueTypeId: Scalars['String']
    venueType: VenueType
    slug: Scalars['String']
    companyName: Scalars['String']
    description: Scalars['String']
    phoneNumber: Scalars['String']
    contactPersonName: Scalars['String']
    email: Scalars['String']
    ownerId: Scalars['String']
    owner: User
    address: Address
    businessHours: Array<BusinessHours>
    images: Array<Image>
    prices: Array<Price>
    createdAt: Scalars['DateTime']
    updatedAt: Scalars['DateTime']
    courts: Array<Court>
    minimumBookingMinutes: Scalars['Float']
    unitBookingMinutes: Scalars['Float']
    startMinutesOfBookings: Scalars['String']
    events: Array<Event>
}

export type VenueInput = {
    name: Scalars['String']
    companyName: Scalars['String']
    phoneNumber: Scalars['String']
    contactPersonName: Scalars['String']
    email: Scalars['String']
    addressInput: AddressInput
    businessHoursInput: Array<BusinessHoursInput>
    price: Scalars['Float']
}

export type VenueType = {
    __typename?: 'VenueType'
    id: Scalars['ID']
    name: Scalars['String']
    venues: Array<Venue>
}

export type Address = {
    __typename?: 'address'
    id: Scalars['ID']
    country?: Maybe<Scalars['String']>
    state?: Maybe<Scalars['String']>
    zip?: Maybe<Scalars['String']>
    city?: Maybe<Scalars['String']>
    line1?: Maybe<Scalars['String']>
    lat?: Maybe<Scalars['Float']>
    lng?: Maybe<Scalars['Float']>
}

export type Booking = {
    __typename?: 'booking'
    id: Scalars['ID']
    userId: Scalars['String']
    user: User
    from: Scalars['DateTime']
    to: Scalars['DateTime']
    price: Scalars['Float']
    paid: Scalars['Boolean']
    status: BookingStatus
    createdAt: Scalars['DateTime']
    validUntil: Scalars['DateTime']
    completedAt: Scalars['DateTime']
    comment: Scalars['String']
    courtId: Scalars['ID']
    court: Court
    seasonBookingId?: Maybe<Scalars['String']>
    seasonBooking?: Maybe<SeasonBooking>
}

export type RegisterMutationVariables = Exact<{
    input: RegisterInput
}>

export type RegisterMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'register'>

export type LoginMutationVariables = Exact<{
    input: LoginInput
}>

export type LoginMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'login'>

export type ChangePasswordMutationVariables = Exact<{
    currentPw: Scalars['String']
    newPw: Scalars['String']
    newPwAgain: Scalars['String']
}>

export type ChangePasswordMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'changePassword'>

export type EditProfileMutationVariables = Exact<{
    input: EditProfileInput
}>

export type EditProfileMutation = { __typename?: 'Mutation' } & {
    editProfile: { __typename?: 'User' } & Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'gender'> & {
            address?: Maybe<{ __typename?: 'address' } & Pick<Address, 'country' | 'zip' | 'city' | 'line1'>>
        }
}

export type SocialAuthMutationVariables = Exact<{
    input: SocialAuthInput
}>

export type SocialAuthMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'socialAuth'>

export type GetPhoneValidationMutationVariables = Exact<{
    phone: Scalars['String']
    firstName: Scalars['String']
    lastName: Scalars['String']
}>

export type GetPhoneValidationMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'getSmsValidationByPhone'>

export type ValidateAccountMutationVariables = Exact<{
    code: Scalars['String']
}>

export type ValidateAccountMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'validate'>

export type MeQueryVariables = Exact<{ [key: string]: never }>

export type MeQuery = { __typename?: 'Query' } & {
    me: { __typename?: 'User' } & Pick<
        User,
        'id' | 'firstName' | 'lastName' | 'email' | 'gender' | 'avatar' | 'validated' | 'phone'
    > & { address?: Maybe<{ __typename?: 'address' } & Pick<Address, 'country' | 'zip' | 'line1' | 'city'>> }
}

export type GetBookingsOfUserQueryVariables = Exact<{ [key: string]: never }>

export type GetBookingsOfUserQuery = { __typename?: 'Query' } & {
    getBookingsOfUser: Array<
        { __typename?: 'booking' } & Pick<Booking, 'id' | 'from' | 'to' | 'price'> & {
                court: { __typename?: 'Court' } & Pick<Court, 'name'> & {
                        venue: { __typename?: 'Venue' } & Pick<Venue, 'name'>
                    }
            }
    >
}

export type StorePartnerMutationVariables = Exact<{
    mail: Scalars['String']
    analiticsId?: Maybe<Scalars['String']>
}>

export type StorePartnerMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'storePartnerContact'>

export type VenuesQueryVariables = Exact<{
    keyword?: Maybe<Scalars['String']>
}>

export type VenuesQuery = { __typename?: 'Query' } & {
    searchVenues: Array<
        { __typename?: 'Venue' } & Pick<
            Venue,
            'id' | 'name' | 'slug' | 'companyName' | 'description' | 'phoneNumber' | 'contactPersonName' | 'email'
        > & {
                businessHours: Array<
                    { __typename?: 'BusinessHours' } & Pick<BusinessHours, 'dayOfWeek' | 'openTime' | 'closeTime'>
                >
                images: Array<{ __typename?: 'Image' } & Pick<Image, 'url'>>
                address: { __typename?: 'address' } & Pick<
                    Address,
                    'id' | 'lat' | 'lng' | 'line1' | 'city' | 'zip' | 'state' | 'country'
                >
            }
    >
}

export type VenueQueryVariables = Exact<{
    idOrSlug: Scalars['String']
}>

export type VenueQuery = { __typename?: 'Query' } & {
    getVenue: { __typename?: 'Venue' } & Pick<
        Venue,
        'id' | 'name' | 'slug' | 'companyName' | 'phoneNumber' | 'contactPersonName' | 'description' | 'email'
    > & {
            images: Array<{ __typename?: 'Image' } & Pick<Image, 'url'>>
            courts: Array<{ __typename?: 'Court' } & Pick<Court, 'surface' | 'name'>>
            address: { __typename?: 'address' } & Pick<
                Address,
                'id' | 'lat' | 'lng' | 'line1' | 'city' | 'zip' | 'state' | 'country'
            >
        }
}

export type SearchLocationAndVenueQueryVariables = Exact<{
    keyword?: Maybe<Scalars['String']>
}>

export type SearchLocationAndVenueQuery = { __typename?: 'Query' } & {
    searchLocationsAndVenues: Array<
        | ({ __typename?: 'Location' } & Pick<Location, 'location' | 'count'>)
        | ({ __typename?: 'Venue' } & Pick<Venue, 'id' | 'name' | 'slug'> & {
                  images: Array<{ __typename?: 'Image' } & Pick<Image, 'url'>>
              })
    >
}

export type GetBookableSlotsQueryVariables = Exact<{
    venueId: Scalars['String']
    date: Scalars['DateTime']
    length: Scalars['Float']
}>

export type GetBookableSlotsQuery = { __typename?: 'Query' } & {
    getBookableSlots: Array<
        { __typename?: 'BookableOutput' } & Pick<BookableOutput, 'id' | 'venueId' | 'from' | 'to' | 'price'>
    >
}

export type CreateBookingMutationVariables = Exact<{
    venueId: Scalars['String']
    from: Scalars['DateTime']
    to: Scalars['DateTime']
    price: Scalars['Float']
}>

export type CreateBookingMutation = { __typename?: 'Mutation' } & {
    createBooking: { __typename?: 'booking' } & Pick<Booking, 'id'>
}

export type CanCreateBookingQueryVariables = Exact<{
    venueId: Scalars['String']
    from: Scalars['DateTime']
    to: Scalars['DateTime']
    price: Scalars['Float']
}>

export type CanCreateBookingQuery = { __typename?: 'Query' } & Pick<Query, 'canCreateBooking'>
