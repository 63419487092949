import { withApollo } from '@apollo/apollo'
import { getLayout } from '@components/layout/NavLayout'
import { VenueDetailsHeader } from '@components/section/Venue/VenueDetailsHeader'
import { VenueDetailsInfo } from '@components/section/Venue/VenueDetailsInfo'
import { Footer } from '@global/Footer'
import { useVenueQuery, Venue } from '@graphql/gqlHooks'
import { useRouter } from 'next/router'
import { VenueBookingPicker } from '@components/section/Venue/VenueBookingPicker'
import { MobileBottomBar } from '@components/global/Navigation/MobileBottomBar'
import { AppAuth } from '../../components/global/Navigation/AppAuth'
import { AppLogo } from '../../components/global/Navigation/AppLogo'
import { IoIosArrowBack } from 'react-icons/io'
const VenueDetails = () => {
    const { query } = useRouter()
    const { data, loading, error } = useVenueQuery({
        variables: {
            idOrSlug: query.venueId as string,
        },
    })

    const router = useRouter()

    if (error) return <div>Error</div>
    if (loading) return <div>Loading</div>

    const venue = (data.getVenue as Venue) ?? null

    return (
        <>
            <nav className="fixed top-0 z-40 hidden w-full py-4 bg-white shadow-md h-nav md:flex md:flex-col ">
                <div className="justify-between hidden md:flex ">
                    <AppLogo isScrolled={true} />
                    <AppAuth isScrolled={true} />
                </div>
            </nav>
            <div onClick={() => router.back()} className="flex items-center mt-10 ml-4 md:hidden">
                <IoIosArrowBack className="mr-2" />
                Vissza
            </div>
            <VenueDetailsHeader venue={venue} />
            <div className="flex items-center justify-center mx-4 md:hidden">
                <VenueBookingPicker venue={venue} />
            </div>
            <VenueDetailsInfo venue={venue} />
            <Footer />
            {/* <MobileBottomBar /> */}
        </>
    )
}

export default withApollo({ ssr: true })(VenueDetails)
