import { CourtSurface, Venue } from '@graphql/gqlRequests'
import { AiOutlineCar } from 'react-icons/ai'
import { GiTennisCourt } from 'react-icons/gi'
import { HiBell, HiCake, HiCube, HiOutlinePhone, HiOutlineThumbUp } from 'react-icons/hi'
import { VenueBookingPicker } from './VenueBookingPicker'
import { VenueDetailsMap } from './VenueDetailsMap'

type VenueDetailsInfoProps = {
    venue: Venue
}

export const VenueDetailsInfo: React.FC<VenueDetailsInfoProps> = ({ venue }) => {
    // salakos és kemény teniszpályák száma
    const clayCourtsCount = venue.courts?.filter((x) => x.surface == CourtSurface.Clay).length
    const hardCourtCount = venue.courts?.filter((x) => x.surface == CourtSurface.Hard).length

    // pályaleírás összerakása
    let courtDescription = ''
    if (clayCourtsCount > 0 && hardCourtCount > 0) {
        courtDescription = `${clayCourtsCount} salakos és ${hardCourtCount} kemény pálya.`
    } else if (clayCourtsCount > 0) {
        courtDescription = `${clayCourtsCount} salakos pálya. `
    } else if (hardCourtCount > 0) {
        courtDescription = `${hardCourtCount} kemény pálya.`
    }

    return (
        <div className="bg-white">
            <div className="flex flex-1 mx-6 mt-12 text-gray-900 md:mx-auto md:container ">
                <div className="w-full mx-4 lg:w-3/5">
                    <h1 className="text-3xl font-semibold ">Információ</h1>
                    <div className="mt-4 border border-b border-gray-200" />
                    <div className="flex flex-col mt-6 lg:flex-row">
                        <div className="flex flex-col w-full lg:w-1/2">
                            <div className="flex">
                                <GiTennisCourt className="w-8 h-full " />
                                <div className="ml-10">
                                    <h1 className="text-lg font-semibold">{venue.courts?.length} teniszpálya </h1>
                                    <p className="text-sm text-gray-600">{courtDescription}</p>
                                </div>
                            </div>
                            <div className="flex mt-6">
                                <HiOutlineThumbUp className="w-8 h-full mr-10" />
                                <div className="">
                                    <h1 className="text-lg font-semibold">Online foglalás</h1>
                                    <p className="text-sm text-gray-600">Lehetőséged van online foglalni.</p>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col w-full mt-6 lg:w-1/2 lg:ml-4 lg:mt-0">
                            <div className="flex">
                                <AiOutlineCar className="w-8 h-full mr-10" />
                                <div className="">
                                    <h1 className="text-lg font-semibold">Parkolás</h1>
                                    <p className="text-sm text-gray-600">A pálya rendelkezik saját parkolóval.</p>
                                </div>
                            </div>
                            <div className="flex mt-6">
                                <HiOutlinePhone className="w-8 h-full mr-10" />
                                <div className="">
                                    <h1 className="text-lg font-semibold">Telefonos foglalás</h1>
                                    <p className="text-sm text-gray-600">Telefonon is tudsz foglalni.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4 border border-b border-gray-200" />
                    <div className="my-4">
                        <h2 className="text-lg font-semibold">Leírás</h2>
                        <p className="mt-4 text-sm text-gray-600">
                            {venue.description.split('\\n').map((item, key) => {
                                return (
                                    <p key={key}>
                                        {item}
                                        <br />
                                    </p>
                                )
                            })}
                        </p>
                        <div className="mt-4 border border-b border-gray-200" />
                    </div>
                    <h1 className="text-3xl font-semibold">Elhelyezkedés</h1>

                    <div className="flex flex-col items-center w-full mt-12 mb-4 ">
                        <VenueDetailsMap venue={venue} />
                    </div>
                    <div className="flex flex-col my-10">
                        <div className="border border-b border-gray-200" />
                    </div>
                </div>
                <div className="items-start justify-center hidden mx-2 mb-6 md:flex md:w-full lg:w-2/5">
                    <VenueBookingPicker venue={venue} />
                </div>
            </div>
        </div>
    )
}
